import axios from 'axios';
import { toast } from 'react-toastify';

export default async function getHistory(body) {
  try {
    const { data } = await axios.post(process.env.REACT_APP_HISTORY, body);
    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
}
