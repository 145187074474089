import { getAgent, changeAgent } from '../asyncService/agentService';
import { createReducer, createAction, createAsyncThunk, combineReducers } from '@reduxjs/toolkit';

async function changeAgentAction(data, { rejectWithValue }) {
  const { agentId, body } = data;
  try {
    return await changeAgent(agentId, body);
  } catch (error) {
    console.log(error.message);
    return rejectWithValue(error);
  }
}

export const getAgentInfo = createAsyncThunk('agent/getAgent', async (id, { rejectWithValue }) => {
  try {
    return await getAgent(id);
  } catch (error) {
    console.log(error.message);
    return rejectWithValue(error);
  }
});

export const changeAgentDepartments = createAsyncThunk(
  'agent/changeDepartments',
  changeAgentAction
);

export const changeAgentLangs = createAsyncThunk('agent/changeLangs', changeAgentAction);

export const changeAgentMainInfo = createAsyncThunk('agent/changeMainInfo', changeAgentAction);

export const clearAgent = createAction('agent/clear');

const infoReducer = createReducer({}, (builder) => {
  builder
    .addCase(getAgentInfo.fulfilled, (_, action) => action.payload)
    .addCase(changeAgentDepartments.pending, (state, _) => ({ ...state, depLoading: true }))
    .addCase(changeAgentDepartments.fulfilled, (state, action) => ({
      ...state,
      departments: action.payload.departments.map((el) => {
        const [dep] = state.availableDepartments.filter((elem) => elem.id === el.id);
        return { ...el, ...dep };
      }),
      depLoading: false
    }))
    .addCase(changeAgentDepartments.rejected, (state, _) => ({ ...state, depLoading: false }))
    .addCase(changeAgentLangs.fulfilled, (state, action) => ({
      ...state,
      langs: action.payload.languages
    }))
    .addCase(changeAgentMainInfo.pending, (state, _) => ({ ...state, infoLoading: true }))
    .addCase(
      changeAgentMainInfo.fulfilled,
      (state, { payload: { name, email, config, roles } }) => ({
        ...state,
        name,
        email,
        config,
        roles,
        infoLoading: false
      })
    )
    .addCase(changeAgentMainInfo.rejected, (state, _) => ({ ...state, infoLoading: false }))
    .addCase(clearAgent, (_, __) => ({}));
});

const loadReducer = createReducer(false, (builder) => {
  builder
    .addCase(getAgentInfo.pending, (_, __) => true)
    .addCase(getAgentInfo.fulfilled, (_, __) => false)
    .addCase(getAgentInfo.rejected, (_, __) => false);
});

export const agentReducer = combineReducers({
  info: infoReducer,
  isLoading: loadReducer
});
