import { createReducer, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import getChildren from 'asyncService/getChildren';

export const logIn = createAction('user/login');
export const logOut = createAction('user/logout');

export const getChildrenAction = createAsyncThunk(
  'user/getChildren',
  async (leadEmail, { rejectWithValue }) => {
    try {
      return await getChildren(leadEmail);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const userReducer = createReducer({}, (builder) => {
  builder
    .addCase(logIn, (_, action) => action.payload)
    .addCase(logOut, (_, __) => ({}))
    .addCase(getChildrenAction.fulfilled, (state, action) => ({
      ...state,
      children: action.payload,
      isChildrenLoad: false
    }))
    .addCase(getChildrenAction.pending, (state, _) => ({ ...state, isChildrenLoad: true }))
    .addCase(getChildrenAction.rejected, (state, _) => ({ ...state, isChildrenLoad: false }));
});
