import { combineReducers, createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import getHistory from 'asyncService/getHistory';

export const getHistoryAction = createAsyncThunk(
  'history/get',
  async (body, { rejectWithValue }) => {
    try {
      return await getHistory(body);
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error);
    }
  }
);

export const clearHistory = createAction('history/clear');

const getHistoryReducer = createReducer([], (builder) => {
  builder
    .addCase(getHistoryAction.fulfilled, (_, action) => action.payload)
    .addCase(clearHistory, (_, __) => []);
});

const historyLoaderReducer = createReducer(false, (builder) => {
  builder
    .addCase(getHistoryAction.pending, (_, __) => true)
    .addCase(getHistoryAction.fulfilled, (_, __) => false)
    .addCase(getHistoryAction.rejected, (_, __) => false);
});

export const historyReducer = combineReducers({
  state: getHistoryReducer,
  isLoading: historyLoaderReducer
});
