import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const Home = lazy(() => import('components/Home'));
const Header = lazy(() => import('./components/Header/Header'));
const AgentProfile = lazy(() => import('./components/AgentProfile/AgentProfile'));
const General = lazy(() => import('./components/AgentProfile/General/General'));
const Localize = lazy(() => import('./components/AgentProfile/Localize/Localize'));
const Activity = lazy(() => import('./components/AgentProfile/Activity/Activity'));
const Children = lazy(() => import('./components/AgentProfile/Children/Children'));
const AuthPage = lazy(() => import('./components/AuthPage/AuthPage'));
const PassRecovery = lazy(() => import('./components/AuthPage/PassRecovery/PassRecovery'));
const PassChange = lazy(() => import('./components/AuthPage/PassChange/PassChange'));
const HomeWrapper = lazy(() => import('components/HomeWrapper'));
const AuthForm = lazy(() => import('./components/AuthForm/AuthForm'));
const History = lazy(() => import('./components/History/History'));
const ChatPage = lazy(() => import('./components/ChatPage/ChatPage'));

function App() {
  const token = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Header />}>
            <Route path="" element={<AuthPage />}>
              <Route path="registration" element={<AuthForm role="registration" />} />
              <Route path="login" element={<AuthForm role="login" />} />
            </Route>
            <Route path="recovery" element={<PassRecovery />} />
            <Route path="password-change" element={<PassChange />} />
            <Route path="" element={<HomeWrapper />}>
              <Route path="panel" element={<Home />} />
              <Route path="agent/:agentId" element={<AgentProfile />}>
                <Route path="general" element={<General />} />
                <Route path="localize" element={<Localize />} />
                <Route path="activity" element={<Activity />} />
                <Route path="children" element={<Children />} />
              </Route>
              <Route path="history" element={<History />} />
              <Route path="history/:chatId" element={<ChatPage />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
