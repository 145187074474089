import axios from 'axios';
import { toast } from 'react-toastify';

export async function disconnectAgent(id) {
  try {
    const { data } = await axios(`${process.env.REACT_APP_TO_OFFLINE}${id}`);
    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
}

export const getAgent = async (agentId) => {
  try {
    const { data } = await axios(`${process.env.REACT_APP_AGENT_BASE_URL}/${agentId}`);

    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
};

export const getAvailableLocale = async () => {
  try {
    const { data } = await axios(`${process.env.REACT_APP_LOCALE}`);
    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
};

export const changeAgent = async (agentId, body) => {
  try {
    const { data } = await axios.put(`${process.env.REACT_APP_AGENT_BASE_URL}/${agentId}`, {
      entity: body
    });
    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
};

export const getAgentActivity = async (body) => {
  try {
    const { data } = await axios.post(process.env.REACT_APP_ACTIVITY, body);

    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
};

export const getAgents = async () => {
  try {
    const { data } = await axios(process.env.REACT_APP_GET_AGENTS);
    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
};

export const recoveryPassword = async (body) => {
  const { data } = await axios.post(process.env.REACT_APP_RECOVERY_PASS, body);
  return data;
};

export const changePassword = async (body) => {
  const { data } = await axios.patch(process.env.REACT_APP_CHANGE_PASS, body);
  return data;
};
