import axios from 'axios';
import { toast } from 'react-toastify';

export default async function getChildren(leadEmail) {
  try {
    const { data } = await axios(`${process.env.REACT_APP_GET_CHILDREN}?leadEmail=${leadEmail}`);

    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
}
