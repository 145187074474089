import axios from 'axios';
import { toast } from 'react-toastify';

export const getWebimState = async () => {
  try {
    const { data } = await axios(`${process.env.REACT_APP_WEBIM_CURRENT_URL}`);
    return data;
  } catch (error) {
    toast.error('Что-то пошло не так...');
  }
};
